body{
  background:#f1f3f9
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: Arial, sans-serif;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;
}

.header {
  font-size: 3em;
  margin-top: 60px;
  text-align: center;
}
.gameId{
  font-weight:600;
  font-size:20px;
}
.top-of-input{
  text-align:left !important;
  display:flex;
  align-items: center;
  width:100%;
  justify-content: space-between;
}
.final{
  text-align:center;
}

.description {
  text-align: center;
  max-width: 80%;
  font-size:18px;
  line-height:1.5;
  margin: 0 auto;
  margin-bottom:30px;
}
.timer{
  font-weight:bold;
}
.password-input {
  padding: 10px;
  font-size: 1em;
  margin-top:10px;
  border-radius: 4px;
  background:#fff;
  border: 1px solid #ccc;
  height:30px;
  box-shadow:1px 3px 3px rgba(0,0,0,0.05);
  margin-bottom: 1em;
  width: 100%;
}

.success-message {
  color: green;
  font-weight: bold;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rule {
  width: 100%;
  padding: 15px;
  margin: 5px 0;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rule-success {
  background: #d4edda;
  color: #155724;
}

.rule-failure {
  background: #f8d7da;
  color: #721c24;
}

.rule-number {
  font-weight: bold;
}

.tick {
  color: green;
}

.cross {
  color: red;
}

.faq-section{
  max-width: 600px;
  width: 100%; 
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.faq-section h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.faq-section details {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

.faq-section summary {
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.faq-section p {
  color: #666;
  margin-left: 20px;
}